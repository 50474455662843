import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import InternetExtraPackage from '@/components/internet-extra-package'
import InternetPackageGrid from '@/components/internet-package-grid'
import Newrelic from '@/components/newrelic-agent'
import PrivilegeShelf from '@/components/privilege-shelf'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ShortcutMenu from '@/components/shortcut-menu'
import TolBanner from '@/components/tol-banner'
import TopBanner from '@/components/top-banner'
import useTrueOnlinePageDataLayer from '@/hooks/data-layers/use-true-online-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TrueOnlinePackageTypePage = ({ serverData }) => {
  let [
    packageType,
    headerScript,
    brandHeaders,
    productServices,
    shortcutMenus,
    tolPackages,
    tolContent,
    footerHeaders,
    chatShop,
    contactWithSaleContent,
    tolBottomBanner,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let { handleMessage, createScopeProps } = useTrueOnlinePageDataLayer()

  if (!packageType) {
    return <NotFoundPage />
  }

  let topBanners = packageType.topBanners
  let IEP = packageType.tolExtraPackages

  tolPackages = tolPackages.map(pack => {
    return {
      ...pack,
      serviceBy: pack.serviceBy
        ? pack.serviceBy === 'self'
          ? { buttonOnCard: pack.locale === 'th' ? 'สมัครเลย' : 'Apply' }
          : { buttonOnCard: pack.locale === 'th' ? 'ดูรายละเอียด' : 'Details' }
        : { buttonOnCard: pack.locale === 'th' ? 'ดูรายละเอียด' : 'Details' },
    }
  })
  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={packageType.seo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {packageType.topBanners.length > 0 && (
          <DataLayerScope
            {...createScopeProps('TopBannerPackageType', { topBanners })}
          >
            <TopBanner banners={topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <DataLayerScope {...createScopeProps('ShortcutMenuPackageType')}>
            <Section.Background className="py-4" color="light-blue">
              <ShortcutMenu color="blue" items={shortcutMenus} />
            </Section.Background>
          </DataLayerScope>
        )}
        {tolPackages.length > 0 && (
          <div className="my-5">
            <SectionHeading variant="underline" tag="h1">
              {packageType.name}
            </SectionHeading>
            <SectionHeading className="mt-3 mb-4" level="2">
              {packageType.detail}
            </SectionHeading>
            <DataLayerScope
              {...createScopeProps('InternetPackageGrid', {
                tolPackages,
                header: packageType,
              })}
            >
              <InternetPackageGrid
                packages={tolPackages}
                content={tolContent}
                contactWithSaleContent={contactWithSaleContent}
              />
            </DataLayerScope>
          </div>
        )}
        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tol" : "trueonline" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueOnlinePackageTypePage

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      `tol-package-types/${params.slug}`,
      'header-script',
      'brand-headers',
      'product-services',
      'tol-shortcut-menus',
      `tol-package-types/${params.slug}/packages`,
      'tol-package-box',
      'footer-headers',
      'chat-and-shop',
      'tol-chat-with-sale-staff',
      'tol-bottom-banner',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
