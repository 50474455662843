import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { breakpoints } from 'swp-components/lib/assets/config/style'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button, DataLayer } from 'swp-components/lib/components/common'
import { Card } from 'swp-components/lib/components/internet-package'

import useColumns from '@/hooks/use-columns'

import style from './style'

const InternetPackageGrid = ({
  packages,
  content,
  contactWithSaleContent,
  ...props
}) => {
  let { locale, defaultLocale } = useContext(I18nContext)

  packages = packages.map(pack => {
    if (pack['bundleStatus'] === true || pack['bundleStatus'] === null) {
      delete pack['bundleContent']
    } else {
      delete pack['image1']
      delete pack['image2']
      delete pack['image3']
    }
    return {
      ...pack,
      detailLink: {
        url: localizePath(
          '/trueonline' +
            `/package-types/${pack.packageOption.tolPackageType.slug}` +
            `/packages/${pack.slug}`,
          locale,
          defaultLocale
        ),
        newTab: false,
      },
    }
  })

  let handleAction = pack => {
    return action => {
      if (action === 'show-modal') {
        window.location.href = pack.detailLink.url
      }
    }
  }

  let [rows, setRows] = useState(2)

  let { columns } = useColumns([
    { value: breakpoints.sm, columns: 2 },
    { value: breakpoints.lg, columns: 3 },
    { value: breakpoints.xl, columns: 4 },
  ])

  let centered = packages.length < columns

  let visiblePackages = packages.slice(0, columns * rows)
  visiblePackages.map(pack => delete pack.buttonName2)

  return (
    <div {...props}>
      <Container>
        <div css={style.wrapper(centered)}>
          {visiblePackages.map(pack => (
            <div
              key={pack.id}
              css={style.box}
              style={{ width: `${100 / columns}%` }}
            >
              <Card
                css={style.card}
                pack={pack}
                onAction={handleAction(pack)}
              />
            </div>
          ))}
        </div>
        {visiblePackages.length < packages.length && (
          <div className="d-flex justify-content-center mt-3">
            <DataLayer
              selector={`InternetPackageGrid.viewAllButton`}
              triggers={['click']}
              context={packages}
              render={ref => (
                <Button
                  ref={ref}
                  variant="outlined"
                  onClick={() => setRows(rows + 99)}
                >
                  <FormattedMessage id="viewMore" defaultMessage="View More" />
                </Button>
              )}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default InternetPackageGrid
